<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Translations
        </h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert align-v="center" variant="warning" :show="saveMessage !== ''">
          {{ this.saveMessage }}
        </b-alert>
      </b-col>
      <b-col cols="auto">
        <b-button variant="outline-primary" @click="openNewTranslationModal">
          Add translation
        </b-button>
        <b-button
          :disabled="saveDisabled"
          variant="primary"
          @click="doSave"
          class="ml-2"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <h3 class="mt-4">Activate Languages</h3>
    <b-form-checkbox
      v-model="selected"
      v-for="(translation, index) in translations"
      :key="index"
      :value="index"
      class="ml-3 language"
      button
      button-variant="outline-primary"
    >
      <!-- <font-awesome-icon class="delete-icon-language" @click="deleteLanguage(index)" icon="times-circle"></font-awesome-icon> -->
      <country-flag :country="index" size="big" />
    </b-form-checkbox>
    <b-button
      class="ml-3 pb-3 pt-3 pl-4 pr-4"
      variant="outline-primary"
      @click="addLanguage()"
    >
      <font-awesome-icon icon="plus-square" size="lg"></font-awesome-icon>
    </b-button>

    <h3 class="mt-4">Translations</h3>
    <b-tabs class="mt-3">
      <b-tab v-for="language in selected" :key="language">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-container class="mb-5 mt-4" fluid>
          <b-row
            class="my-1"
            v-for="(translation, index) in translations[language]"
            :key="index"
          >
            <b-col sm="3">
              <label>
                <code> {{ index }} </code>:
              </label>
            </b-col>
            <b-col sm="9">
              <b-form-input
                v-model="translations[language][index]"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
    <AddLanguage v-model="showModal" />
    <b-modal
      v-model="showNewTranslationModal"
      title="Add new translation"
      ok-title="Add"
      @ok="doAddTranslation"
    >
      <b-form-group label="New translation key">
        <b-form-input v-model="newKey"> </b-form-input>
      </b-form-group>
    </b-modal>
  </b-overlay>
</template>
<script>
import AddLanguage from "@/components/admin/language/AddLanguage.vue";

export default {
  components: {
    AddLanguage,
  },
  data() {
    return {
      selected: this.$store.state.Languages.all,
      isLoading: false,
      showModal: false,
      newLanguage: "",
      saveDisabled: true,
      saveMessage: "",
      translationValue: "",
      translationLabel: "",
      showNewTranslationModal: false,
      newKey: "",
    };
  },
  async created() {
    await this.$store.dispatch("Translations/getTranslations");
    await this.$store.dispatch("Languages/fetchAll");
    this.selected = this.languages;
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    translations: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
    selected: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    translations() {
      return this.$store.state.Translations.translations;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
  methods: {
    openNewTranslationModal() {
      this.showNewTranslationModal = true;
    },
    doAddTranslation() {
      for (const language of Object.keys(this.translations)) {
        if (!this.translations[language][this.newKey]) {
          this.translations[language][this.newKey] = "";
        }
      }
      this.newKey = "";
    },
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Translations/update", this.translations);
      await this.$store.dispatch("Translations/getTranslations");
      await this.$store.dispatch("Languages/update", this.selected);
      await this.$store.dispatch("Languages/fetchAll");
      if (this.selected.length == 1) {
        this.$root.$i18n.locale = this.selected[0];
        localStorage.setItem("locale", this.selected[0]);
        localStorage.setItem("lang", this.selected[0]);
      }
      this.$root.$bvToast.toast("Page settings saved!", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    addLanguage() {
      this.toggleModal();
    },
    async deleteLanguage(index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove language?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.selected.splice(index, 1);
        await this.$store.dispatch("Languages/update", this.selected);
      }
      this.isLoading = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$color-warning: var(--theme-color-warning);
$color-success: var(--theme-color-success);
$color-danger: var(--theme-color-danger);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.delete-icon-language {
  position: absolute;
  color: $color-danger;
  margin-top: -14px;
  margin-left: 55px;
}

.delete-icon-language:hover {
  color: $color-secondary;
}
</style>
