<template>
  <b-modal
    v-model="value"
    title="Add Language"
    ref="language-modal"
    id="language-modal"
  >
    <b-form-select v-model="selected" :options="allLanguages"> </b-form-select>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">Save</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      selected: "",
      isLoading: false,
      allLanguages: {
        af_ZA: ["Afrikaans", "Afrikaans"],
        ar: ["العربية", "Arabic"],
        bg_BG: ["Български", "Bulgarian"],
        ca_AD: ["Català", "Catalan"],
        cs_CZ: ["Čeština", "Czech"],
        cy_GB: ["Cymraeg", "Welsh"],
        da_DK: ["Dansk", "Danish"],
        de_AT: ["Deutsch (Österreich)", "German (Austria)"],
        de_CH: ["Deutsch (Schweiz)", "German (Switzerland)"],
        de_DE: ["Deutsch (Deutschland)", "German (Germany)"],
        el_GR: ["Ελληνικά", "Greek"],
        en_GB: ["English (UK)", "English (UK)"],
        en: ["English (US)", "English (US)"],
        es_CL: ["Español (Chile)", "Spanish (Chile)"],
        es_ES: ["Español (España)", "Spanish (Spain)"],
        es_MX: ["Español (México)", "Spanish (Mexico)"],
        et_EE: ["Eesti keel", "Estonian"],
        eu: ["Euskara", "Basque"],
        fa_IR: ["فارسی", "Persian"],
        fi_FI: ["Suomi", "Finnish"],
        fr_CA: ["Français (Canada)", "French (Canada)"],
        fr_FR: ["Français (France)", "French (France)"],
        he_IL: ["עברית", "Hebrew"],
        hi_IN: ["हिंदी", "Hindi"],
        hr_HR: ["Hrvatski", "Croatian"],
        hu_HU: ["Magyar", "Hungarian"],
        id_ID: ["Bahasa Indonesia", "Indonesian"],
        is_IS: ["Íslenska", "Icelandic"],
        it_IT: ["Italiano", "Italian"],
        ja_JP: ["日本語", "Japanese"],
        km_KH: ["ភាសាខ្មែរ", "Khmer"],
        ko_KR: ["한국어", "Korean"],
        la: ["Latina", "Latin"],
        lt_LT: ["Lietuvių kalba", "Lithuanian"],
        lv_LV: ["Latviešu", "Latvian"],
        mn_MN: ["Монгол", "Mongolian"],
        nb_NO: ["Norsk bokmål", "Norwegian (Bokmål)"],
        nl: ["Nederlands", "Dutch"],
        nn_NO: ["Norsk nynorsk", "Norwegian (Nynorsk)"],
        pl_PL: ["Polski", "Polish"],
        pt_BR: ["Português (Brasil)", "Portuguese (Brazil)"],
        pt_PT: ["Português (Portugal)", "Portuguese (Portugal)"],
        ro_RO: ["Română", "Romanian"],
        ru_RU: ["Русский", "Russian"],
        sk_SK: ["Slovenčina", "Slovak"],
        sl_SI: ["Slovenščina", "Slovenian"],
        sr_RS: ["Српски / Srpski", "Serbian"],
        sv_SE: ["Svenska", "Swedish"],
        th_TH: ["ไทย", "Thai"],
        tr_TR: ["Türkçe", "Turkish"],
        uk_UA: ["Українська", "Ukrainian"],
        vi_VN: ["Tiếng Việt", "Vietnamese"],
        zh_CN: ["中文 (中国大陆)", "Chinese (PRC)"],
        zh_TW: ["中文 (台灣)", "Chinese (Taiwan)"],
      }
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      (this.translations[this.selected] = {
        comingsoon: "",
        QRbutton: "",
        QRtitle: "",
        admin: "",
        profile: "",
        logout: "",
        login: "",
        account: "",
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        updateaccount: "",
        signup: "",
        goback: "",
        code: "",
        livestream: "",
        conversation: "",
        attachments: "",
        attachmentsOpen: "",
        acceptPolicy: "",
        privacyPolicy: "",
        social: "",
        passwordRequest: "",
        passwordRequestTitle: "",
        passwordSuccessTitle: "",
        passwordSuccessDescription: "",
        passwordSuccessButton: "",
        sendEmail: "",
        layoutAndPages: "",
        features: "",
        homePage: "",
        header: "",
        staticPages: "",
        styling: "",
        translations: "",
        livestreamWebinar: "",
        livestreams: "",
        usersAndSupport: "",
        users: "",
        notifications: "",
        mails: "",
        mailTemplates: "",
        sendMail: "",
        intro: "",
        content: "",
        interaction: "",
        saveAll: "",
        save: "",
        unsavedChanges: "",
        savedChanges: "",
        active: "",
        title: "",
        description: "",
        type: "",
        buttonText: "",
        buttonLink: "",
        tagDescription: "",
        addTag: "",
        addBlock: "",
        delete: "",
        deleteQuestion: "",
        deletePrompt: "",
        ok: "",
        cancel: "",
        close: "",
        slides: "",
        addSlide: "",
        image: "",
        backgroundImage: "",
        actions: "",
        browse: "",
        url: "",
        category: "",
        items: "",
        addItem: "",
        date: "",
        startDate: "",
        endDate: "",
        chatEmbed: "",
        link: "",
        roles: "",
        role: "",
        user: "",
        moderator: "",
        add: "",
        html: "",
        preview: "",
        linkSlug: "",
        logo: "",
        brandingColors: "",
        textColors: "",
        semanticColors: "",
        primaryColor: "",
        secondaryColor: "",
        tertiaryColor: "",
        headerTextColor: "",
        titleTextColor: "",
        bodyTextColor: "",
        successColor: "",
        dangerColor: "",
        warningColor: "",
        logoDescription: "",
        backgroundDescription: "",
        choosePrimary: "",
        chooseSecondary: "",
        chooseTertiary: "",
        chooseHeadertext: "",
        chooseTitletext: "",
        chooseBodytext: "",
        chooseSuccess: "",
        chooseDanger: "",
        chooseWarning: "",
        activateLanguages: "",
        platform: "",
        livestreamUrl: "",
        apiKey: "",
        apiSecret: "",
        leaveUrl: "",
        chatQa: "",
        speakers: "",
        name: "",
        avatar: "",
        roomUrl: "",
        createNewRoom: "",
        createRoom: "",
        hostRoomUrl: "",
        export: "",
        import: "",
        chooseFileImport: "",
        general: "",
        logs: "",
        sendEveryone: "",
        message: "",
        insertText: "",
        sendNotification: "",
        pastNotifications: "",
        subject: "",
        actionUrl: "",
        createdAt: "",
        beforeText: "",
        afterText: "",
        useHtml: "",
        buttonLabel: "",
        buttonUrl: "",
        recipient: "",
        from: "",
        sendAt: "",
        host: "",
        token: "",
        backgroundColor: "",
        foregroundColor: "",
        mailTemplate: "",
        chooseForeground: "",
        chooseBackground: "",
        watchingNow: "",
        upNext: "",
        meetSpeakers: "",
      }),
        (this.value = false);
      this.isLoading = false;
    },
  },
  computed: {
    translations() {
      return this.$store.state.Translations.translations;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
